import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Typography } from "@material-ui/core";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import OnboardStepsNew from "../onboarding/OnboardStepsNew";
import setupStepsConfig from "./setupStepsConfig";
import { getFirstIncompleteStep } from "../onboarding/OnboardStepsNew/onboardUtil";

// Services
import { getCarrierId } from "app/services/LoginService";
import { getOnboardingData } from "app/services/onboardingServices";

//Redux
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

function SetupPage() {
	const dispatch = useDispatch();
	const history = useHistory();
	const setupRevision = useSelector(({ tools }) => tools.revision["setupRevision"]);
	const [error, setError] = useState(null);
	const [data, setData] = useState(null);
	const carrierId = getCarrierId();
	const [step, setStep] = useState(-1);

	const onFinished = async () => {
		dispatch(fetchActions({ carrierId: carrierId }));
		dispatch(incrementDataRevision({ event: "setupRevision" }));
		history.push(`/home`);
	};

	useEffect(() => {
		const loadData = async () => {
			try {
				const onboardingData = await getOnboardingData(carrierId);
				console.log("onboardingData", onboardingData);
				setData(onboardingData);
			} catch (error) {
				setError(`Failed to load setup information (${error.message ?? "Unknown"})`);
			}
		};
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setupRevision]);

	useEffect(() => {
		if (data) {
			const configuration = setupStepsConfig(
				!!data.axleConnection,
				data.onboardingInformationStatus === "VALIDATED_LOCKED"
			);
			const nextStep = getFirstIncompleteStep({ config: configuration, data });
			if (nextStep === configuration.list.length - 1 && configuration.list[nextStep].isComplete(data)) {
				onFinished();
			} else {
				setStep(nextStep);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	if (error) {
		return <Typography className="text-13 pb-10 text-red text-center pt-80 px-20">{error}</Typography>;
	}
	if (!data) {
		return <FuseSplashScreen />;
	}

	return (
		<OnboardStepsNew
			step={step}
			setStep={setStep}
			onSwitchSteps={(prevStep, newStep) => {
				console.log({ prevStep, newStep });
			}}
			config={setupStepsConfig(!!data.axleConnection, data.onboardingInformationStatus === "VALIDATED_LOCKED")}
			data={{
				...data,
			}}
			props={{ setupRevision }}
		/>
	);
}

export default SetupPage;
