import ValidateMCView from "app/main/setup/components/ValidateMCView";
import SetupSidebar from "./components/SetupSidebar";
import SetupIntro from "./components/SetupIntro";
import CreateTruck from "./components/CreateTruck";
import CreateDriver from "./components/CreateDriver";
import { Typography } from "@material-ui/core";

const setupStepConfig = (axleIntegrated, alreadyValidated) => {
	let steps = [];

	steps.push({
		title: "Connect your ELD",
		pageTitle: "Congrats!",
		itemClass: "",
		isAllowed: (data) => data.onboardingInformationStatus !== "VALIDATED_LOCKED",
		isComplete: (data) => data.onboardingInformationStatus && data.onboardingInformationStatus === "VALIDATED_LOCKED",
		render: ({ data, switchToStep }) => {
			return (
				<div className="w-full md:w-10/12">
					<SetupIntro data={data} switchToStep={switchToStep} />
				</div>
			);
		},
	});

	if (!axleIntegrated || (axleIntegrated && !alreadyValidated)) {
		steps.push({
			title: "Validate your MC",
			pageTitle: "Validate your MC",
			itemClass: "",
			isAllowed: (data) => data.onboardingInformationStatus !== "VALIDATED_LOCKED",
			isComplete: (data) => data.onboardingInformationStatus && data.onboardingInformationStatus === "VALIDATED_LOCKED",
			render: ({ data, switchToStep }) => {
				return (
					<div className="w-full md:w-10/12">
						<ValidateMCView data={data} switchToStep={switchToStep} />
					</div>
				);
			},
		});
	}

	if (axleIntegrated) {
		steps.push({
			title: "Sync trucks",
			pageTitle: "Sync trucks",
			itemClass: "",
			isComplete: (data) => data.truck_complete,
			isAllowed: (data) => data.onboardingInformationStatus === "VALIDATED_LOCKED" && !data.truck_complete,
			render: ({ data, switchToStep }) => {
				return (
					<div className="w-full md:w-10/12">
						<Typography>Placeholder for trucks sync</Typography>
					</div>
				);
			},
		});
		steps.push({
			title: "Sync drivers",
			pageTitle: "Sync drivers",
			itemClass: "",
			isComplete: (data) => data.drivers_complete,
			isAllowed: (data) =>
				data.onboardingInformationStatus === "VALIDATED_LOCKED" && data.truck_complete && !data.drivers_complete,
			render: ({ data, switchToStep }) => {
				return (
					<div className="w-full md:w-10/12">
						<Typography>Placeholder for drivers sync</Typography>
					</div>
				);
			},
		});
	} else {
		steps.push({
			title: "Set up one truck",
			pageTitle: "Set up one truck",
			itemClass: "",
			isComplete: (data) => data.truck_complete,
			isAllowed: (data) => data.onboardingInformationStatus === "VALIDATED_LOCKED" && !data.truck_complete,
			render: ({ data, switchToStep }) => {
				return (
					<div className="w-full md:w-10/12">
						<CreateTruck data={data} switchToStep={switchToStep} />
					</div>
				);
			},
		});
		steps.push({
			title: "Set up one driver",
			pageTitle: "Set up one driver",
			itemClass: "",
			isComplete: (data) => data.drivers_complete,
			isAllowed: (data) =>
				data.onboardingInformationStatus === "VALIDATED_LOCKED" && data.truck_complete && !data.drivers_complete,
			render: ({ data, switchToStep }) => {
				return (
					<div className="w-full md:w-10/12">
						<CreateDriver data={data} switchToStep={switchToStep} />
					</div>
				);
			},
		});
	}

	return {
		path: "/setup",
		sidebarHeader: "Setup",
		renderSidebarDescription: ({ config, data }) => <SetupSidebar config={config} data={data} />,
		sidebarClass: "bg-[#E6E8EA]",
		list: steps,
	};
};

export default setupStepConfig;
