// Components
import PlatformAvailablePlansView from "app/main/billing/plans/PlatformAvailablePlansView";
import RegisterForm from "../auth/register/RegisterForm";
import RegisterTermAcknowledgement from "../auth/register/RegisterTermAcknowledgement";
import SignupSidebar from "./SignupSidebar";
import PlatformPlanView from "app/main/billing/plans/PlatformPlanView";

const stepConfig = {
	path: "/signup",
	sidebarHeader: "Sign Up",
	renderSidebarDescription: ({ config, data }) => <SignupSidebar config={config} data={data} />,
	sidebarClass: "bg-[#E6E8EA]",
	list: [
		{
			title: "Create Account",
			subtitle: "Enter your account details",
			pageTitle: "Create Your Account",
			pageSubtitle: "Please enter your email and create a password",
			isAllowed: (data) => !data?.userId && data?.authRegister?.status !== "EMAIL_VERIFICATION_REQUIRED",
			isComplete: (data) => !!data?.userId || data?.authRegister?.status === "EMAIL_VERIFICATION_REQUIRED",
			render: ({ dispatch, data }) => {
				return data?.verification_token ? (
					<div className="w-full md:w-10/12">
						<RegisterForm viewType="register" verification_token={data?.verification_token} newRegistration={true} />
					</div>
				) : (
					<div className="w-full md:w-10/12">
						<RegisterForm viewType="register" verification_type="EMAIL" newRegistration={true} />
					</div>
				);
			},
		},
		{
			title: "Email Verification",
			subtitle: "Verify your email address",
			pageTitle: "Verify Your Email",
			pageSubtitle: "Please check your inbox and verify your email address",
			isComplete: (data) => !!data?.userId || !!data?.authRegister?.email_confirmed,
			isAllowed: (data) => data?.authRegister?.status === "EMAIL_VERIFICATION_REQUIRED" && !data?.verification_token,
			hidden: (data) => !!data?.verification_token || data?.urlVerificationToken,
			render: ({ data }) => {
				return (
					<div className="w-full md:w-10/12">
						<RegisterForm viewType="register" verification_type="EMAIL" newRegistration={true} />
					</div>
				);
			},
		},
		{
			title: "Select Plan",
			subtitle: "Choose your subscription plan",
			pageTitle: "Select Your Plan",
			pageSubtitle: ["Select the plan that best fits your needs.", "Cancel anytime. No hidden fees ever."],
			isComplete: (data) =>
				!!data?.tier ||
				(!!data?.authRegister?.signupSelectedPlan?.planId && !!data?.authRegister?.signupSelectedPlan?.priceId),
			isAllowed: (data) => !!data?.userId,
			hidden: (data) => !!data?.authRegister?.signupSelectedPlan?.urlParams || data?.urlPlanId,
			render: ({ carrierId }) => {
				return (
					<div className="flex flex-col w-full items-center justify-center -mt-14">
						<PlatformAvailablePlansView
							showPromoCodeBar
							carrierId={carrierId}
							allowToPreselectPlan={true}
							mode="SIGNUP"
						/>
					</div>
				);
			},
			classes: { contentContainer: "md:w-full mx-20" },
		},
		{
			title: "Acknowledge Terms",
			pageTitle: "Acknowledge Terms",
			isComplete: (data) => {
				return !!data?.tier || !!data?.authRegister?.signupTermsAcknowledgementAccepted;
			},
			isAllowed: (data) => {
				return (!!data?.userId && !!data?.authRegister?.signupSelectedPlan) || !!data?.tier;
			},
			render: () => {
				return (
					<div className="w-full md:w-10/12">
						<RegisterTermAcknowledgement />
					</div>
				);
			},
			classes: { contentContainer: "md:w-full mx-20" },
		},
		{
			title: "Submit Payment",
			pageTitle: "Submit Payment",
			isComplete: (data) => !!data?.tier,
			isAllowed: (data) => {
				return (
					!!data?.tier ||
					(!!data?.authRegister?.signupTermsAcknowledgementAccepted &&
						!!data?.authRegister?.signupSelectedPlan?.planId &&
						!!data?.authRegister?.signupSelectedPlan?.priceId)
				);
			},
			render: ({ carrierId, userId, data }) => {
				return (
					<div className="w-full md:w-10/12">
						<PlatformPlanView
							key="signup-submit-payment"
							dataIds={{
								carrierId,
								userId,
								planId: data?.authRegister?.signupSelectedPlan?.planId,
								priceId: data?.authRegister?.signupSelectedPlan?.priceId,
							}}
						/>
					</div>
				);
			},
			classes: {
				contentContainer: "md:w-full mx-20",
			},
		},
	],
};

export default stepConfig;
