import { Alert } from "@material-ui/lab";

import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { isEnabled } from "app/services/featureStorageService";

//Fields
const MAX_WEIGHT_FIELD = (required = true, disabled = false) => {
	return { key: "max_weight", type: "number", label: "Max Weight", min: 0, max: 199999, required, disabled };
};

const TRAILER_LENGTH_FIELD = (required = true, disabled = false) => {
	return {
		key: "trailer",
		type: "select",
		label: "Length",
		required,
		disabled,
		options: [
			{
				value: 53,
				label: "53'",
			},
			{
				value: 48,
				label: "48'",
			},
			{
				value: 45,
				label: "45'",
			},
			{
				value: 28,
				label: "28'",
			},
			{
				value: 26,
				label: "26'",
			},
			{
				value: 15,
				label: "15'",
			},
		],
	};
};

const LOAD_TYPE_SELECTOR = (required = false, disabled = false, excludeBoth = false) => {
	return {
		key: "load_type",
		type: "select",
		label: "Capacity",
		required,
		disabled,
		options: [
			{
				value: "FULL",
				label: "Only Full Loads",
			},
			{
				value: "PARTIAL",
				label: "Only Partial Loads (LTL)",
			},
			...(excludeBoth
				? []
				: [
						{
							value: "BOTH",
							label: "Partial and Full Loads",
						},
				  ]),
		],
	};
};

const _HAZMAT_CHECKBOX = {
	key: "hazmat",
	type: "checkbox",
	label: "Hazmat",
};

const SPECIAL_EQ_SELECTOR = (subtype, disabled = false, required = false) => {
	return {
		key: "special_equipment",
		type: "select",
		label: "Subtype",
		description: "Posting truck requires a specific subtype",
		required,
		disabled,
		options: [
			...(subtype === "Box Truck"
				? [
						{
							label: "Straight Box Truck",
							value: "SB",
						},
				  ]
				: []),
			...(subtype === "Cargo Van"
				? [
						{
							label: "Moving Van",
							value: "MV",
						},
						{
							label: "Van",
							value: "VAN",
						},
						{
							label: "Van HotShot",
							value: "VH",
						},
				  ]
				: []),
			...(subtype === "Conestoga"
				? [
						{
							label: "Van Conestoga",
							value: "VS",
						},
						{
							label: "Flatbed Conestoga",
							value: "FN",
						},
						{
							label: "Stepdeck Conestoga",
							value: "SN",
						},
				  ]
				: []),
			...(subtype === "Deck"
				? [
						{
							label: "B-Train",
							value: "BT",
						},
						{
							label: "Double Drop",
							value: "DD",
						},
						{
							label: "Drop Deck Landoll",
							value: "LA",
						},
						{
							label: "Dump Trailer",
							value: "DT",
						},
						{
							label: "Flatbed Maxi",
							value: "MX",
						},
						{
							label: "Flatbed or Step Deck",
							value: "FD",
						},
						{
							label: "Lowboy",
							value: "LB",
						},
						{
							label: "Lowboy or RGN",
							value: "LR",
						},
						{
							label: "Lowboy Over Dimension",
							value: "LO",
						},
						{
							label: "Removable Gooseneck",
							value: "RG",
						},
						{
							label: "Step Deck",
							value: "SD",
						},
						{
							label: "Step Deck or RGN",
							value: "SR",
						},
						{
							label: "Stretch Trailer",
							value: "ST",
						},
						{
							label: "Truck and Trailer",
							value: "TT",
						},
				  ]
				: []),
			...(subtype === "HotShot"
				? [
						{
							label: "Flatbed HotShot",
							value: "FH",
						},
						{
							label: "Van HotShot",
							value: "VH",
						},
				  ]
				: []),
			...(subtype === "Tanker"
				? [
						{
							label: "Tanker Aluminum ",
							value: "TA",
						},
						{
							label: "Tanker Intermodal",
							value: "TN",
						},
						{
							label: "Tanker Steel",
							value: "TS",
						},
				  ]
				: []),
		],
	};
};

const EQUIPMENT_TRAILER_FIELDS = (equipment, subtype) => {
	if (!equipment || equipment === "PO") return [];

	const isflatBed = equipment === "FLATBED";
	const isSpecial = equipment === "SPECIAL";

	return [
		...(isSpecial ? [SPECIAL_EQ_SELECTOR(subtype)] : []),
		{
			type: "group",
			content: {
				items: [
					{
						key: "trailerid",
						type: "text",
						label: "ID",
						required: true,
						preventCharacter: true,
						minCharacter: 1,
						maxCharacter: 15,
					},
					{
						key: "trailer_vin",
						type: "text",
						label: "VIN #",
						field: { maxLength: 30 },
					},
				],
			},
		},
		{
			type: "group",
			content: {
				items: [MAX_WEIGHT_FIELD(), TRAILER_LENGTH_FIELD()],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{ key: "trailer_year", type: "number", label: "Trailer Year" },

					...(!isflatBed && !isSpecial
						? [
								{
									key: "doors",
									type: "select",
									label: "Type of Doors",
									options: [
										{
											value: "SWING_DOORS",
											label: "Swing doors",
										},
										{
											value: "ROLL_UP_DOORS",
											label: "Rolling doors",
										},
									],
								},
						  ]
						: [{ type: "stub" }]),
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					LOAD_TYPE_SELECTOR(),
					_HAZMAT_CHECKBOX,
					{ key: "trailer_strap", type: "checkbox", label: "Does it have straps" },
					{
						key: "trailer_food_grade",
						type: "checkbox",
						label: "Food grade trailer",
					},
				],
			},
		},
		{
			key: "trailer_registration",
			type: "upload",
			label: "Trailer Registration",
			upload: {
				url: "api/profile/carriers/:carrierId/files",
				documentType: "truck",
				fileName: "trailer_registration",
			},
		},
	];
};

const TRAILER_FIELDS = () => {
	return [
		{
			key: "equipment",
			type: "select",
			label: "Equipment",
			required: true,
			defaultValue: "VAN",
			options: [
				{
					value: "VAN",
					label: "Van",
					showItems: EQUIPMENT_TRAILER_FIELDS("VAN"),
				},
				{
					value: "REEFER",
					label: "Reefer",
					showItems: EQUIPMENT_TRAILER_FIELDS("REEFER"),
				},
				{
					value: "FLATBED",
					label: "Flatbed",
					showItems: EQUIPMENT_TRAILER_FIELDS("FLATBED"),
				},
				...(isEnabled("ENABLE_SPECIAL_EQUIPMENTS")
					? [
							{
								value: "SPECIAL_Box Truck",
								label: "Box Truck",
								showItems: EQUIPMENT_TRAILER_FIELDS("SPECIAL", "Box Truck"),
							},
							{
								value: "SPECIAL_Cargo Van",
								label: "Cargo Van",
								showItems: EQUIPMENT_TRAILER_FIELDS("SPECIAL", "Cargo Van"),
							},
							{
								value: "SPECIAL_Conestoga",
								label: "Conestoga",
								showItems: EQUIPMENT_TRAILER_FIELDS("SPECIAL", "Conestoga"),
							},
							{
								value: "SPECIAL_Deck",
								label: "Deck",
								showItems: EQUIPMENT_TRAILER_FIELDS("SPECIAL", "Deck"),
							},
							{
								value: "SPECIAL_HotShot",
								label: "HotShot",
								showItems: EQUIPMENT_TRAILER_FIELDS("SPECIAL", "HotShot"),
							},
							{
								value: "SPECIAL_Tanker",
								label: "Tanker",
								showItems: EQUIPMENT_TRAILER_FIELDS("SPECIAL", "Tanker"),
							},
					  ]
					: []),
			],
		},
	];
};

const TRAILER_LITE_FIELDS = (isLiteVersion) => {
	return [
		{
			key: "equipment",
			type: "select",
			label: "Equipment",
			required: true,
			defaultValue: "VAN",
			options: [
				{
					value: "VAN",
					label: "Van",
				},
				{
					value: "REEFER",
					label: "Reefer",
				},
				{
					value: "FLATBED",
					label: "Flatbed",
				},

				{
					value: "SPECIAL_Box Truck",
					label: "Box Truck",
				},
				{
					value: "SPECIAL_Cargo Van",
					label: "Cargo Van",
				},
				{
					value: "SPECIAL_Conestoga",
					label: "Conestoga",
				},
				{
					value: "SPECIAL_Deck",
					label: "Deck",
				},
				{
					value: "SPECIAL_HotShot",
					label: "HotShot",
				},
				{
					value: "SPECIAL_Tanker",
					label: "Tanker",
				},
			],
		},
		TRAILER_LENGTH_FIELD(),
	];
};

//Only used for delete
const trailerForm = {
	urlPOST: "api/profile/carriers/:carrierId/trailers",
	urlGET: "api/profile/carriers/:carrierId/trailers/:id",
	urlPUT: "api/profile/carriers/:carrierId/trailers/:id",
	urlDELETE: "api/profile/carriers/:carrierId/trailers/:id/status/:status",
	idKey: "_id",
	triggerEvent: ["trailerRevision", "dataRevision"],
	listenEvent: "trailerRevision",
	header: {
		create: { label: "Add New Trailer" },
		edit: { label: "Edit Trailer" },
		delete: { label: "Change Trailer Status" },
		view: { label: "View Trailer" },
	},
	content: {
		create: {
			noInitValidation: true,
			items: [...TRAILER_FIELDS(), { type: "action", action: "SUBMIT" }],
		},
		edit: {
			sameAs: "create",
			onFinished: (_, dispatch) => {
				dispatch(incrementDataRevision({ event: "profileRevision" }));
			},
		},
		view: {
			sameAs: "create",
		},
		delete: {
			items: [
				{
					type: "message",
					builder: (item) => (
						<div>
							{item?.trucks?.length > 0 && !!item.active && (
								<Alert className="flex flex-row w-full mb-16" severity="info">
									This trailer is assigned. If the trailer is archive it is unassigned from the truck.
								</Alert>
							)}
							<span className="ml-5">
								Would you like to {!!item.active ? "archive" : "activate"} trailer with ID '{item.trailerid}'?
							</span>
						</div>
					),
				},
				{
					type: "group",
					content: {
						items: [
							{ type: "action", action: "CANCEL" },
							{ type: "action", action: "DELETE", label: "Continue" },
						],
					},
				},
			],
			onFinished: (_, dispatch) => {
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				if (window?.ReactNativeWebView) {
					window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_TRAILER_LIST" }));
				}
			},
		},
	},
};

export {
	trailerForm,
	TRAILER_FIELDS,
	EQUIPMENT_TRAILER_FIELDS,
	SPECIAL_EQ_SELECTOR,
	MAX_WEIGHT_FIELD,
	TRAILER_LENGTH_FIELD,
	LOAD_TYPE_SELECTOR,
	TRAILER_LITE_FIELDS,
};
