import { createSlice } from "@reduxjs/toolkit";
import { setUserData } from "./userSlice";
import { signUp } from "app/services/LoginService";
// Segment
import { SIGN_UP_EVENT } from "app/main/segment/segmentType";
import { VERIFY_CONTACT } from "app/main/segment/segmentType";
import { createTrackOrPage } from "app/main/segment/segmentEvent";
import { applyPromotionCode } from "app/services/couponServices";

export const submitRegister = (data, onProgressOverride, onDoneOverride) => async (dispatch) => {
	dispatch(registerInProgress());
	dispatch(clearErrors());
	let queryString = localStorage.getItem("campaign");
	if (queryString) {
		let queryParams = JSON.parse(queryString);
		data.utm_campaign = queryParams.utm_campaign;
		data.utm_source = queryParams.utm_source;
		data.utm_params = queryParams;
	}

	if (onProgressOverride) onProgressOverride(true);
	return signUp(data).then(
		(singUpResponse) => {
			if (onProgressOverride) onProgressOverride(false);

			if (
				singUpResponse.status === "EMAIL_VERIFICATION_REQUIRED" ||
				singUpResponse.status === "PHONE_VERIFICATION_REQUIRED" ||
				singUpResponse.status === "VERIFICATION_TYPE_REQUIRED"
			) {
				console.log("[RegisterSlice] signUp requires email validation");
				// Track Email Verification
				createTrackOrPage(VERIFY_CONTACT, singUpResponse, "track");
				return dispatch(setRegisterStatus(singUpResponse.status));
			}

			if (onDoneOverride) {
				// IMPORTANT: Do not dispatch event if callback preent, this callback used for registration on mobile,
				// mobile does not require saving any data locally when using web form to register
				console.log("[RegisterSlice] signUp on done overriden");
				onDoneOverride(singUpResponse);
			} else {
				dispatch(setUserData(singUpResponse));
			}
			createTrackOrPage(SIGN_UP_EVENT, singUpResponse, "track");
			if (singUpResponse?.carrierId) {
				const couponCode = localStorage.getItem("coupon");
				if (couponCode) {
					applyPromotionCode(singUpResponse.carrierId, couponCode);
					localStorage.removeItem("coupon");
				}
			}

			return dispatch(registerSuccess());
		},
		(error) => {
			if (error?.data?.errors?.length > 0) {
				let message = error?.data?.errors?.[0]?.message;
				if (onProgressOverride) onProgressOverride(false, message);
				return dispatch(registerError(error.data.errors));
			} else {
				let message = "Something went wrong, please try again later.";
				if (onProgressOverride) onProgressOverride(false, message);
				return dispatch(registerError([{ type: "generic", message }]));
			}
		}
	);
};

export const clearRegisterationErrors = () => async (dispatch) => {
	dispatch(clearErrors());
};

const initialState = {
	status: null,
	success: false,
	inProgress: false,
	errors: [],
	signupSelectedPlan: null,
};

const registerSlice = createSlice({
	name: "auth/register",
	initialState,
	reducers: {
		registerInProgress: (state) => {
			state.inProgress = true;
		},
		registerSuccess: (state) => {
			state.errors = [];
			state.status = "DONE";
			state.success = true;
			state.inProgress = false;
		},
		setRegisterStatus: (state, action) => {
			state.errors = [];
			state.success = action.payload === "DONE";
			state.status = action.payload;
			state.inProgress = false;
		},
		registerError: (state, action) => {
			state.success = false;
			state.inProgress = false;
			state.errors = action.payload;
		},
		setSignupSelectedPlan: (state, action) => {
			state.signupSelectedPlan = action.payload;
		},
		setSignupTermsAcknowledgementAccepted: (state, action) => {
			state.signupTermsAcknowledgementAccepted = action.payload;
		},
		clearErrors: (state) => {
			state.errors = [];
		},
	},
	extraReducers: {},
});

export const {
	registerInProgress,
	setRegisterStatus,
	registerSuccess,
	registerError,
	clearErrors,
	setSignupSelectedPlan,
	setSignupTermsAcknowledgementAccepted,
} = registerSlice.actions;

export default registerSlice.reducer;
