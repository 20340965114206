import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { setSignupTermsAcknowledgementAccepted } from "app/store/auth/registerSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const RegisterTermAcknowledgement = () => {
	const authRegister = useSelector(({ auth }) => auth.register);
	const [checked, setChecked] = useState(authRegister?.signupTermsAcknowledgementAccepted ?? false);
	const dispatch = useDispatch();

	const updateChecked = (isChecked) => {
		setChecked(isChecked);
		dispatch(setSignupTermsAcknowledgementAccepted(isChecked));
	};

	return (
		<div className="w-full max-w-md mx-auto p-8">
			<div className="space-y-6">
				<Typography className="text-15 md:text-16 text-gray-600 leading-relaxed pb-16">
					Before paying for SmartHop, we want to make sure you understand that there are a few steps you&apos;ll have to
					take before using the platform.
				</Typography>

				<ol className="list-decimal list-inside text-gray-700 pb-16 text-15 md:text-16">
					<li className="pl-2 text-15 md:text-16 ">Provide a valid MC</li>
					<li className="pl-2 text-15 md:text-16 ">Create at least one driver and one truck</li>
					<li className="pl-2 text-15 md:text-16 ">Attend a 15-minute onboarding call with our amazing team</li>
				</ol>

				<div className="mt-8 pt-12 border-t border-gray-200 pb-16">
					<Typography className="text-15 md:text-16 text-gray-600 leading-relaxed">
						By moving to the next step, you acknowledge that you understand and agree to the above requirements, and
						that you understand that we do not allow refunds.
					</Typography>
				</div>
			</div>
			<FormControlLabel
				control={
					<Checkbox checked={checked} onChange={(event) => updateChecked(event.target.checked)} name="confirmedMC" />
				}
				label={<Typography className="text-15 md:text-16 text-gray-600">I agree to the terms above</Typography>}
			/>
		</div>
	);
};

export default RegisterTermAcknowledgement;
