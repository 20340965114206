import { Box, CircularProgress, Icon, Typography } from "@material-ui/core";
import { isStepCompleted } from "./onboardUtil";
import { getCarrierId, getUserId } from "app/services/LoginService";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import OnboardSubSteps from "./OnboardSubSteps";
import { useState } from "react";
import { useEffect } from "react";

function OnboardContent({ loading, step, config, data, switchToStep, onCompleteSteps, loadingLastStep, props }) {
	const dispatch = useDispatch();
	const history = useHistory();

	const dataIds = props.dataIds;
	const isCreateSubAccount = !!dataIds?.createSubAccount;

	const carrierId = isCreateSubAccount ? dataIds?.carrierId : getCarrierId();
	const userId = isCreateSubAccount ? dataIds?.userId : getUserId();
	const activeConfig = useMemo(() => config.list[step], [config, step]);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !isMobile) {
				setIsMobile(true);
			} else if (window.innerWidth > 960 && isMobile) {
				setIsMobile(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const renderHeader = () => {
		const stepCompleted = isStepCompleted({ step, config, data });
		const iconClass = "text-24 md:text-28 mr-6 " + (stepCompleted ? " text-green-400 " : " text-grey-800 ");

		return (
			<div className="flex flex-col w-full items-center justify-center pb-16 px-6 ml:pb-24">
				<div className="flex flex-row items-center px-16 pb-6 relative">
					<Icon className={iconClass}>{stepCompleted ? "check_circle" : ""}</Icon>
					<Typography className="text-20 md:text-24 text-left">{activeConfig.pageTitle}</Typography>
					<Icon className={iconClass}></Icon>
					{loading && (
						<div className="absolute" style={{ right: -10 }}>
							<CircularProgress size={isMobile ? 20 : 30} />
						</div>
					)}
				</div>
				{Array.isArray(activeConfig.pageSubtitle) ? (
					activeConfig.pageSubtitle.map((line, i) => (
						<Typography
							key={"pageSubtitle_" + i}
							color={"textSecondary"}
							className={"text-13 text-left max-w-780 " + (i === 0 ? " -mt-4 " : "")}
						>
							{line}
						</Typography>
					))
				) : (
					<Typography color={"textSecondary"} className="text-13 text-center max-w-780 -mt-4">
						{activeConfig.pageSubtitle}
					</Typography>
				)}
			</div>
		);
	};

	if (!activeConfig) return null;

	return (
		<div className="flex flex-col w-full items-center">
			<div
				className={`flex flex-col items-center w-full mt-0 md:-mt-4 ${
					activeConfig?.classes?.contentContainer ?? "md:w-11/12"
				}`}
			>
				{renderHeader(step)}
				<div className="flex w-full items-center justify-center">
					{activeConfig?.renderSteps && (
						<OnboardSubSteps
							data={data}
							substepsViews={activeConfig?.renderSteps({
								data,
								carrierId,
								userId,
								config,
								switchToStep,
								onCompleteSteps,
								dispatch,
								history,
								step,
								loadingLastStep,
								props,
							})}
						/>
					)}
					{activeConfig?.render &&
						typeof activeConfig?.render === "function" &&
						activeConfig?.render({
							data,
							carrierId,
							userId,
							config,
							switchToStep,
							onCompleteSteps,
							dispatch,
							history,
							step,
							loadingLastStep,
							props,
						})}
					{activeConfig?.render && typeof activeConfig?.render !== "function" && activeConfig?.render}
				</div>
			</div>
		</div>
	);
}

export default OnboardContent;
