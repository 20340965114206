import { Link, makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { isEnabled } from "app/services/featureStorageService";
import { openFormDialog } from "app/store/tools/formDialogSlice";

const useStyles = makeStyles((theme) => ({
	numberCircleActive: {
		display: "flex",
		width: "fit-content",
		minWidth: "4rem",
		padding: "0.5rem",
		alignItems: "center",
		justifyContent: "center",
		aspectRatio: "1 / 1",
		borderRadius: "50%",
		border: "2px solid white",
		color: "white",
		backgroundColor: "#ef6631",
		marginRight: 16,
	},
}));

const WHITE_LISTED_PATHS = ["/signup", "/setup"];

function InReviewBlocker({}) {
	const enabled = useMemo(() => isEnabled("NEW_ONBOARDING_FLOW_V3"), []);
	const classes = useStyles();
	const location = useLocation();
	const dispatch = useDispatch();

	const { pathname } = location;
	const user = useSelector(({ auth }) => auth?.user);
	const inReview = useMemo(() => user?.onboardingStatus === "IN_REVIEW", [user]);
	const inReviewBlocker = useMemo(() => {
		if (!enabled || !inReview || WHITE_LISTED_PATHS.includes(pathname)) return null;

		return (
			<div
				className="fixed h-screen w-screen left-0 top-0 bg-white z-50 overflow-hidden"
				style={{ background: "rgba(0, 0, 0, 0.5)" }}
			>
				<div
					className="relative w-4/5 lg:w-2/5 bg-white opacity-100 flex flex-col content-center justify-center items-center"
					style={{ left: "50%", transform: "translate(-50%, -50%)", top: "50%", height: "300px" }}
				>
					<div className="flex flex-col content-center justify-center">
						<h2>You're Almost Ready to Go!</h2>
						<div className="flex flex-row content-center items-center mb-8">
							<div className={classes.numberCircleActive}>1</div>
							<h3 className="mt-8">
								Validate Your MC Number{" "}
								<Link
									onClick={() =>
										dispatch(openFormDialog({ viewId: "COMPANY_INFO_CONFIRM", dataIds: { carrierId: user.carrier } }))
									}
								>
									here
								</Link>
							</h3>
						</div>
						<div className="flex flex-row content-center items-center mb-8">
							<div className={classes.numberCircleActive}>1</div>
							<h3 className="mt-8">
								Setup Your Account <Link href="/setup">here</Link>
							</h3>
						</div>
						<div className="flex flex-row">
							<div className={classes.numberCircleActive}>2</div>
							<h3 className="mt-8">
								Complete your{" "}
								<Link target="_blank" href="https://meetings.hubspot.com/will-elliot/smarthop-onboarding-call">
									onboarding call
								</Link>
							</h3>
						</div>
						<p className="flex flex-row mt-16">This page will unlock when you finish these tasks</p>
					</div>
				</div>
			</div>
		);
	}, [classes.numberCircleActive, inReview, pathname, enabled]);

	return inReviewBlocker;
}

export default InReviewBlocker;
