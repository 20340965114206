function SetupSidebar({ config, data }) {
	return (
		<>
			<img className="logo-icon w-200 " src="assets/images/logoSmartHopSECOND.png" alt="logo" />
			<h2 className="text-white">Set up your Account</h2>
		</>
	);
}

export default SetupSidebar;
