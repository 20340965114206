import RegisterForm from "./RegisterForm";
import LandingPageContainer from "../LandingPageContainer";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import { isEnabled } from "app/services/featureStorageService";

function Register() {
	return isEnabled("NEW_ONBOARDING_FLOW_V3") || true ? (
		<Redirect to="/signup" />
	) : (
		<LandingPageContainer viewType="register">
			<RegisterForm viewType="register" />
		</LandingPageContainer>
	);
}

export default Register;
