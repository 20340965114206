import { Button, Icon } from "@material-ui/core";
import { hasPrevStep, hasNextStep, isStepAllowed } from "./onboardUtil";
import { useMemo } from "react";

function OnboardFooter({ step, config, data, switchToStep, setMobileSidebarOpen, mobileSidebarOpen, props }) {
	const navDisabled = useMemo(() => config.navDisabled?.({ config, data }), [config, data]);
	config.list = config.list.filter((list) => {
		if (list.hidden && list.hidden(data)) {
			return false;
		}
		return true;
	});

	const hasNext = useMemo(
		() => hasNextStep({ step, config, data }) && isStepAllowed({ step: step + 1, config, data }),
		[step, config, data]
	);

	const hasPrev = useMemo(
		() => props.isDesktop && hasPrevStep({ step, config, data }) && isStepAllowed({ step: step - 1, config, data }),
		[props.isDesktop, step, config, data]
	);

	const hasMobileLink = useMemo(
		() => (props.isMobile || props.nativeMobile) && !mobileSidebarOpen,
		[props.isMobile, props.nativeMobile, mobileSidebarOpen]
	);

	if (!hasNext && !hasPrev && !hasMobileLink) return null;

	return (
		<div className="flex flex-row">
			{props.isDesktop && (
				<div className="flex flex-1 bg-white items-center justify-center py-12 md:py-10 border-t-8 border-b-8 border-l-4 border-r-4 border-grey-100 ">
					{hasPrev && (
						<Button
							disabled={navDisabled}
							onClick={() => {
								if (!navDisabled) {
									switchToStep(step - 1);
								}
							}}
							className="whitespace-nowrap text-14 md:text-14 font-bold py-6 px-16"
						>
							{"< Previous"}
						</Button>
					)}
				</div>
			)}
			{(props.isMobile || props.nativeMobile) && (
				<div className="flex flex-1 bg-white items-center justify-center py-12 md:py-10 border-t-8 border-b-8 border-l-4 border-r-4 border-grey-100 ">
					{!mobileSidebarOpen && (
						<Button
							disabled={navDisabled}
							onClick={() => !navDisabled && setMobileSidebarOpen(true)}
							className="whitespace-nowrap text-14 md:text-14 font-bold py-6 px-16"
						>
							<Icon>menu</Icon>
						</Button>
					)}
				</div>
			)}
			<div
				className={
					"flex flex-1 bg-white items-center justify-center py-12 md:py-10 border-t-8 border-b-8 border-l-4 border-r-4 border-grey-100 "
				}
			>
				{hasNext && (
					<Button
						onClick={() => switchToStep(step + 1)}
						className={
							"whitespace-nowrap px-40 md:px-72 text-14 md:text-14 font-bold py-6 animate-pulse text-green-800 "
						}
					>
						{" Continue >"}
					</Button>
				)}
			</div>
		</div>
	);
}

export default OnboardFooter;
