import { useEffect, useMemo, useState } from "react";
import { Button } from "@material-ui/core";

import { isStepCompleted, isComplete, getFirstIncompleteStep, isStepAllowed } from "./onboardUtil";

import OnboardSidebar from "./OnboardSidebar";
import OnboardContent from "./OnboardContent";
import OnboardFooter from "./OnboardFooter";
import { showWarning, typeWarning } from "app/main/utils/warningUtils";
import { useSelector } from "react-redux";
import { getUserTier } from "app/services/LoginService";
import { useCallback } from "react";
import { isStepVisible } from "../OnboardSteps/onboardUtil";

function OnboardSteps({
	config,
	data,
	onCompleteSteps,
	onSwitchSteps,
	loadingLastStep,
	props,
	restrictions,
	loading,
	step,
	setStep,
}) {
	const filteredConfig = useMemo(() => {
		const c = { ...config };
		c.list = c.list.filter((s, i) => isStepVisible({ step: i, config, data }));
		return c;
	}, [data, config]);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
	const isDesktop = !isMobile && !props?.nativeMobile;
	const isDesktopDialog = isDesktop && props?.isCreateSubAccount;

	const user = useSelector(({ auth }) => auth.user);
	const warning = typeWarning(restrictions, user);

	const planChangeRevision = useSelector(({ tools }) => tools.revision["planChangeRevision"]);
	const planChangeRevisionSoft = useSelector(({ tools }) => tools.revision["planChangeRevisionSoft"]);
	const [userTier, setUserTier] = useState(getUserTier());
	const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);

	const { resource } = props;

	useEffect(() => {
		if (userTier !== getUserTier()) {
			window.location.reload();
			setUserTier(getUserTier());
		}
		// eslint-disable-next-line
	}, [planChangeRevision]);

	useEffect(() => {
		if (userTier !== getUserTier()) {
			// Do not reload page in case of soft reload
			setUserTier(getUserTier());
		}
		// eslint-disable-next-line
	}, [planChangeRevisionSoft]);

	const switchToStep = useCallback(
		(newStep) => {
			onSwitchSteps?.(step, newStep);
			setStep(newStep);
		},
		[onSwitchSteps, step, setStep]
	);

	const activeStepConfig = useMemo(() => filteredConfig.list[step], [filteredConfig, step]);

	useEffect(() => {
		const isAllowed = isStepAllowed({ step, config: filteredConfig, data });
		if (!isAllowed) {
			const firstIncomplete = getFirstIncompleteStep({ config: filteredConfig, data });
			switchToStep(firstIncomplete);
		}
	}, [switchToStep, filteredConfig, data, step]);

	// Checing if screen size change to dynamically adjust size of teh table
	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !isMobile) {
				setIsMobile(true);
			} else if (window.innerWidth > 960 && isMobile) {
				setIsMobile(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const showSidebar = useMemo(
		() => (isDesktop || step === -1) && !activeStepConfig?.hideSidebar && !filteredConfig.hideSidebar,
		[activeStepConfig, filteredConfig, step, isDesktop]
	);
	if (warning) return showWarning(warning, props.title, props.classes, restrictions, resource?.onboarding);

	const height = isDesktopDialog ? { height: "calc(100vh - 150px)" } : isDesktop ? { height: "100vh" } : null;
	return (
		<div className="flex flex-row h-full w-full">
			{(showSidebar || mobileSidebarOpen) && (
				<div
					className={"flex flex-col h-full w-full " + (!isDesktop ? " absolute  " : " md:w-3/12 bg-grey-100 ")}
					style={{ ...height, backgroundColor: "#0f1531" }}
				>
					<div className="flex flex-col items-center w-full pt-40">
						<OnboardSidebar
							step={step}
							config={filteredConfig}
							data={data}
							switchToStep={switchToStep}
							isMobile={isMobile}
							setMobileSidebarOpen={setMobileSidebarOpen}
						/>
					</div>
					{!isDesktop && (
						<div className={"fixed bottom-0 bg-grey-100 w-full mt-20 p-16"}>
							<Button
								className="min-w-95 w-full"
								variant="contained"
								color="secondary"
								onClick={() => setMobileSidebarOpen(false)}
							>
								{isComplete({ config, data })
									? "View Status"
									: isStepCompleted({ config, data, step: 0 })
									? "Continue"
									: "Start"}
							</Button>
						</div>
					)}
				</div>
			)}
			{(isDesktop || !mobileSidebarOpen) && (
				<div
					className={
						"flex flex-col " +
						(props?.nativeMobile
							? " w-screen h-screen absolute pb-80 "
							: !isDesktop
							? " w-full absolute pb-80 "
							: showSidebar
							? " md:w-9/12 w-full bg-white "
							: " w-full ")
					}
					style={height}
				>
					<div className="relative flex flex-row flex-1 w-full overflow-y-scroll overflow-x-hidden pt-20 px-6 md:pt-32 pb-20">
						<OnboardContent
							loading={loading}
							step={step}
							config={filteredConfig}
							data={data}
							onCompleteSteps={onCompleteSteps}
							loadingLastStep={loadingLastStep}
							switchToStep={switchToStep}
							props={{ ...props, isDesktop, isMobile }}
						/>
					</div>
					{!filteredConfig?.hideFooter && (
						<div
							className={
								!isMobile
									? "flex flex-col"
									: data.isDesktopDialog
									? "bottom-0 fixed"
									: "flex flex-col fixed bottom-0 w-full mt-20"
							}
							style={{ zIndex: 100 }}
						>
							<OnboardFooter
								step={step}
								config={filteredConfig}
								data={data}
								switchToStep={switchToStep}
								props={{ ...props, isDesktop, isMobile }}
								setMobileSidebarOpen={setMobileSidebarOpen}
								mobileSidebarOpen={mobileSidebarOpen}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default OnboardSteps;
