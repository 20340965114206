import TruckView from "../../profile/trucks/TruckView";

function CreateTruck({ data, switchToStep }) {
	return (
		<div className="w-full">
			<TruckView key={"setup_truck"} dataIds={{ carrierId: data._id, mode: "CREATE", version: "LITE", switchToStep }} />
		</div>
	);
}

export default CreateTruck;
