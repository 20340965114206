function SignupSidebar({ config, data }) {
	return (
		<div className="md:mb-32 md:mt-32 flex flex-col items-start justify-center ml-16">
			<img className="logo-icon w-200 " src="assets/images/logoSmartHopSECOND.png" alt="logo" />
			<h2 className="text-white">Sign up for Smarthop</h2>
		</div>
	);
}

export default SignupSidebar;
