import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";

import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import OnboardStepsNew from "../onboarding/OnboardStepsNew";
import config from "./signupStepsConfig";
import { getOnboardingData, updatedOnboardingData } from "app/services/onboardingServices";
import { signInWithToken } from "app/services/LoginService";
import { setUserData } from "app/store/auth/userSlice";

// Slices
import { setSignupSelectedPlan } from "app/store/auth/registerSlice";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { useHistory } from "react-router";
import { getFirstIncompleteStep } from "../onboarding/OnboardStepsNew/onboardUtil";

function SignupPage() {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const signupRevision = useSelector(({ tools }) => tools.revision["signupRevision"]);
	const authRegister = useSelector(({ auth }) => auth.register);
	const loggedinUser = useSelector(({ auth }) => auth.user);

	const [error, setError] = useState(null);
	const [data, setData] = useState(null);
	const [verificationToken, setVerificationToken] = useState(null);
	const [loading, setLoading] = useState(false);

	const [step, setStep] = useState(-1);

	const onFinished = async () => {
		let data = await updatedOnboardingData(loggedinUser.carrier);
		let userData = await signInWithToken();
		if (userData) dispatch(setUserData(userData));

		dispatch(fetchActions({ carrierId: loggedinUser.carrier }));
		dispatch(incrementDataRevision({ event: "onboardingRevision" }));
		dispatch(incrementDataRevision({ event: "onboardingUpdateBannerRevision" }));
		dispatch(incrementDataRevision({ event: "paymentRevision" }));
		dispatch(incrementDataRevision({ event: "planChangeRevisionSoft" }));

		history.push(`/home`);
	};

	const searchParams = useMemo(() => new URLSearchParams(location.search), [location?.search]);

	useEffect(() => {
		//let signupSelectedPlan = null;
		const urlPlanId = searchParams.get("planId");
		const urlPriceId = searchParams.get("priceId");

		if (urlPlanId && urlPriceId) {
			dispatch(setSignupSelectedPlan({ planId: urlPlanId, priceId: urlPriceId, urlParams: true }));
		}

		const urlVerificationToken = searchParams.get("verificationToken");
		if (urlVerificationToken) {
			setVerificationToken(urlVerificationToken);
		}
	}, []);

	useEffect(() => {
		// Load initial data or check email verification status
		const loadData = async () => {
			try {
				setLoading(true);
				let onboardingData = null;
				if (loggedinUser?._id && loggedinUser?.carrier) {
					onboardingData = await getOnboardingData(loggedinUser?.carrier);
				}

				setData({
					urlPlanId: searchParams.get("planId"),
					urlPriceId: searchParams.get("priceId"),
					urlVerificationToken: searchParams.get("verificationToken"),
					userId: loggedinUser?._id,
					tier: loggedinUser?.userTier,
					carrierId: loggedinUser?.carrier,
					onboardingStatus: loggedinUser?.onboardingStatus,
					authRegister,
					verification_token: verificationToken || null,
					billing_plan_subscription_complete: onboardingData?.billing_plan_subscription_complete || false,
					signupSelectedPlan: authRegister?.signupSelectedPlan || null,
				});
			} catch (error) {
				setError(`Failed to load signup information (${error.message ?? "Unknown"})`);
			}
			setLoading(false);
		};

		loadData();
	}, [authRegister, loggedinUser, verificationToken, searchParams]);

	useEffect(() => {
		if (data) {
			if (data.onboardingStatus && data.onboardingStatus !== "INCOMPLETE") {
				return history.push(`/home`);
			}
			const nextStep = getFirstIncompleteStep({ config, data });
			if (nextStep === config.list.length - 1 && config.list[nextStep].isComplete(data)) {
				onFinished();
			} else {
				console.log("step:", nextStep);
				setStep(nextStep);
			}
		}
	}, [data]);

	if (error) {
		return <Typography className="text-13 pb-10 text-red text-center pt-80 px-20">{error}</Typography>;
	}

	if (!data) {
		return <FuseSplashScreen />;
	}

	return (
		<OnboardStepsNew
			loading={loading}
			step={step}
			setStep={setStep}
			onSwitchSteps={(prevStep, newStep) => {
				console.log({ prevStep, newStep });
			}}
			config={config}
			data={data}
			props={{ signupRevision }}
		/>
	);
}

export default SignupPage;
