import { Typography, Button, Link } from "@material-ui/core";
import { connectAxle } from "app/services/carrierServices";
import { getCarrierId } from "app/services/LoginService";

function SetupIntro({ data, switchToStep }) {
	const carrierId = getCarrierId();

	return (
		<div className="flex flex-col w-full">
			<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
				You now have an active account. Next is where we will gather necessary information so you can hit the ground
				running
			</Typography>
			<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
				Now, Let's get you setup
			</Typography>
			<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
				Login in to your ELD will make a 5 step, manual input process to just 1 set automatic process
			</Typography>
			<Button
				key="axle"
				color="secondary"
				variant="contained"
				className="px-24 mt-10"
				onClick={() => {
					connectAxle(carrierId).then((data) => {
						window.open(data.url, "_blank");
					});
				}}
			>
				Validate by Connecting ELD with Axle
			</Button>
			<Link className="flex-1 text-13 mb-14 mt-10 font-normal text-center " onClick={() => switchToStep(1)}>
				<Typography className="text-gray-700">Can't connect - Do it manualy</Typography>
			</Link>
		</div>
	);
}

export default SetupIntro;
